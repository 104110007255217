import React from 'react'
import './Home.css'
function Home() {
    return(
        <>
            <div className="home-container">
                <div id="home-text">
                    <h1>Nate Fallowfield</h1>
                    <h3>Interactive Resume</h3>
                </div>
            </div>
        </>
    )
}

export default Home
